export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "cs": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel je již přihlášen. Chcete-li pokračovat, obnovte stránku."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše heslo je kratší než 10 znaků. Chcete-li svůj účet chránit, změňte si heslo tak, aby bylo delší."])}
        },
        "oidc": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknutím na tlačítko Pokračovat přejdete na přihlašovací stránku."])},
          "error": {
            "auth-ok-user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K vaší e-mailové adrese není přiřazen žádný centrální účet. Požádejte prosím správce Central, aby vám vytvořil účet, abyste mohli pokračovat."])},
            "email-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše e-mailová adresa nebyla ověřena přihlašovacím serverem. Obraťte se prosím na správce serveru."])},
            "email-claim-not-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central nemohl získat přístup k e-mailové adrese přidružené k vašemu účtu. Může to být způsobeno tím, že správce serveru něco špatně nakonfiguroval nebo že e-mailovou adresu pro váš účet nenastavil. Může to být také důsledek možností ochrany osobních údajů, které můžete zvolit během přihlašovacího procesu. V takovém případě to zkuste znovu a ujistěte se, že je váš e-mail sdílený."])},
            "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při přihlašování se něco pokazilo. Kontaktujte prosím správce serveru."])}
          }
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávná e-mailová adresa nebo heslo."])}
        }
      },
      "de": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Benutzer ist bereits eingeloggt. Bitte die Seite aktualisieren um weiterzuarbeiten."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort ist kürzer als 10 Zeichen. Um Ihr Konto zu schützen, verlängerns Sie bitte Ihr Passwort."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche E-Mail-Adresse und/oder Passwort."])}
        }
      },
      "es": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un usuario ya ha iniciado sesión. Actualice la página para continuar."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña tiene menos de 10 caracteres. Para proteger su cuenta, cambie su contraseña para que sea más larga."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico y/o contraseña incorrecta."])}
        }
      },
      "fr": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un utilisateur est déjà connecté. Merci de rafraîchir la page pour continuer."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe fait moins de 10 caractères. Pour protéger votre compte, merci de choisir un mot de passe plus long."])}
        },
        "oidc": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur Continuer pour accéder à la page de connexion."])},
          "error": {
            "auth-ok-user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de compte Central associé à votre adresse de courriel. Veuillez demander à votre administrateur de Central de vous créer un compte pour continuer."])},
            "email-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse de courriel n'a pas été vérifiée par votre serveur de connexion. Veuillez contacter l'administrateur de votre serveur."])},
            "email-claim-not-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central n'a pas pu accéder à l'adresse de courriel associée à votre compte. Cela peut être dû au fait que l'administrateur de votre serveur a configuré quelque chose de manière incorrecte ou n'a pas défini d'adresse de courriel pour votre compte. Cela peut également être dû aux options de confidentialité que vous pouvez choisir durant la procédure de connexion. Si c'est le cas, veuillez réessayer et vous assurer que votre adresse de courriel est partagée."])},
            "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème s'est produit lors de la connexion. Veuillez contacter l'administrateur de votre serveur."])}
          }
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de courriel et/ou mot de passe invalides."])}
        }
      },
      "id": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seorang pengguna sudah masuk. Mohon perbarui halaman untuk melanjutkan."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat email dan/atau kata sandi salah."])}
        }
      },
      "it": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un utente ha già effettuato l'accesso. Aggiorna la pagina per continuare."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua password è lunga meno di 10 caratteri. Per proteggere il tuo account, cambia la password per renderla più lunga."])}
        },
        "oidc": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare clic su Continua per passare alla pagina di Login."])},
          "error": {
            "auth-ok-user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al suo indirizzo e-mail non è associato alcun account su Central. Chiedete all'amministratore di Central di creare un account per continuare."])},
            "email-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il vostro indirizzo e-mail non è stato verificato dal server di accesso. Contattare l'amministratore del server."])},
            "email-claim-not-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central non è riuscito ad accedere all'indirizzo e-mail associato al vostro account. Ciò potrebbe essere dovuto al fatto che l'amministratore del server ha configurato qualcosa di errato o non ha impostato un indirizzo e-mail per l'account. Potrebbe anche essere il risultato delle opzioni di privacy che si possono scegliere durante il processo di login. In tal caso, riprovare e assicurarsi che il proprio indirizzo e-mail sia condiviso."])},
            "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcosa è andato storto durante l'accesso. Contattare l'amministratore del server."])}
          }
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo e-mail e/o password errati."])}
        }
      },
      "ja": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでにユーザーでログインされています。 続けるにはページを更新してください。"])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのパスワードは10文字以下です。アカウントを守るため、パスワードを長いものに変更してください。"])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスとパスワードの一方、または両方が違います。"])}
        }
      },
      "sw": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mtumiaji tayari ameingia. Tafadhali onyesha upya ukurasa ili kuendelea."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenosiri lako ni fupi kuliko vibambo 10. Ili kulinda akaunti yako, tafadhali badilisha nenosiri lako ili kuifanya iwe ndefu."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwani ya barua pepe na/au nenosiri si sahihi."])}
        }
      }
    }
  })
}
